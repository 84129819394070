import { saveAs } from "file-saver";

import { storage } from "../../helpers/Firebase";

const AddMediaAsync = async (file, orgId) => {
  const mediaPath = _getMediaPath(orgId, "");
  const ref = storage.ref(mediaPath);
  const name = +new Date() + "-" + file.name;
  const metadata = {
    contentType: file.type,
  };

  const task = ref.child(name).put(file, metadata);
  return await new Promise((resolve, reject) => {
    task
      .then((snapshot) => {
        return snapshot.ref.fullPath.split("/")[1];
      })
      .then((url) => {
        return resolve(url);
      })
      .catch((err) => {
        console.error(err);
        return reject(err);
      });
  });
};

const DownloadMediaAsync = async (fileName, orgId) => {
  // eslint-disable-next-line no-async-promise-executor
  await new Promise(async (resolve) => {
    const mediaPath = _getMediaPath(orgId, fileName);
    const ref = storage.ref(mediaPath);

    const metadata = await ref.getMetadata();

    ref.getDownloadURL().then((url) => {
      // This can be downloaded directly:
      const xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = function () {
        const blob = xhr.response;
        saveAs(blob, metadata.name);
        resolve();
      };
      xhr.open("GET", url, true);
      xhr.send();
    });
  }).catch((error) => {
    console.error(`Error: ${error}`);
  });
};

const _getMediaPath = (orgId, fileName) => orgId + "/" + fileName;

const getPublicFileURLAsync = async (fileStorageRef) => {
  const fileURL = await new Promise((resolve, reject) => {
    fileStorageRef
      .getDownloadURL()
      .then((url) => {
        resolve(url);
      })
      .catch((err) => {
        console.error(err);
        return reject(err);
      });
  });

  return fileURL;
};

export const getPublicWhatsappFileUrlAsync = async (whatsappMediaPath) => {
  const whatsappMediaDecodedPath = decodeURIComponent(whatsappMediaPath);

  const whatsappFileStorageRef = storage.ref(whatsappMediaDecodedPath);

  return await getPublicFileURLAsync(whatsappFileStorageRef);
};

export const addOrgMediaAsync = async (file, path, fileName, customMetadata) => {
  const publicOrgStorageRef = storage.ref(path);

  const metadata = {
    type: file.type,
    contentType: file.type,
    customMetadata,
  };

  const uploadFileTask = publicOrgStorageRef.child(fileName);

  return await new Promise((resolve, reject) => {
    uploadFileTask
      .put(file, metadata)
      .then(async () => {
        const publicURL = await getPublicFileURLAsync(uploadFileTask);
        return resolve(publicURL);
      })
      .catch((err) => {
        console.error(err);
        return reject(err);
      });
  });
};

export const getOrgMediaMetadataAsync = async (path, fileName) => {
  const publicOrgStorageRef = storage.ref(path);

  const fileStorageRef = publicOrgStorageRef.child(fileName);

  return await new Promise((resolve, reject) => {
    fileStorageRef
      .getMetadata()
      .then((metadata) => {
        resolve(metadata);
      })
      .catch((err) => {
        console.error(err);
        return reject(err);
      });
  });
};

export default {
  AddMediaAsync: AddMediaAsync,
  DownloadMediaAsync: DownloadMediaAsync,
};
