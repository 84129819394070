import { EmailNotificationEvent, OrgRef, UserProfile, WebSettings } from "@ouvidor-digital/models";
import Services from "@ouvidor-digital/persistence-services";
import { createUUID } from "@ouvidor-digital/utils";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { NotificationManager } from "../../components/common/react-notifications";
import { DEFAULT_COLOR, getDefaultFormValueByOrgType } from "../../components/org/tabs/constants";
import ANALYTICS_ACTIONS from "../../constants/AnalyticsActions";
import { analytics, auth, db, functions, perf } from "../../helpers/Firebase";
import { intlMessages } from "../../helpers/IntlMessages";
import {
  ADD_ORG,
  DELETE_ORG_MEMBER,
  DISABLE_ORG,
  GET_ORG,
  GET_ORG_USERS,
  GET_ORGS,
  SET_MEMBER_ORGS,
  UPDATE_ORG,
} from "../actions";
import { getVoiceSettings } from "../voiceSettings/actions";

import {
  deleteMemberError,
  deleteMemberSuccess,
  disableOrgError,
  disableOrgSuccess,
  getOrgError,
  getOrgsError,
  getOrgsSuccess,
  getOrgSuccess,
  getUsers,
  getUsersError,
  getUsersSuccess,
  setMemberOrgError,
  setMemberOrgSuccess,
} from "./actions";

const {
  OrgService,
  OrgMemberService,
  EmailNotificationService,
  VoiceSettingsService,
  WebSettingsService,
  RelationshipService,
  LocationService,
  TypeService,
  CustomFieldService,
} = Services;

const orgService = new OrgService(db, perf);
const webSettingsService = new WebSettingsService(db);
const voiceSettingsService = new VoiceSettingsService(db);
const emailNotificationService = new EmailNotificationService(db);
const orgMemberService = new OrgMemberService(db, auth, functions);
const firebaseAnalyticsService = new Services.FirebaseAnalyticsService(analytics);
const relationshipService = new RelationshipService(db, perf);
const locationService = new LocationService(db, perf);
const typeService = new TypeService(db, perf);
const customFieldService = new CustomFieldService(db, perf);

const _toViewModel = (o) => {
  return {
    id: o.id,
    name: o.name,
    ownerEmail: o.owner.email,
    ownerName: o.owner.name,
    ownerPhoneNumber: o.owner.phoneNumber,
    webCode: o.webCode,
    phoneNumber: o.phoneNumber,
    whatsappNumber: o.whatsappNumber,
    plan: o.plan,
    segment: o.segment,
    size: o.size,
    type: o.type,
    locations: o.settings.locations,
    relationships: o.settings.relationships,
    submissionTypes: o.settings.submissionTypes,
    submissionCustomFields: o.settings.submissionCustomFields,
    handlingDescription: o.handlingDescription,
    userLimit: o.userLimit,
    createdDate: o.createdDate,
    updatedDate: o.updatedDate,
    cnpj: o.cnpj,
    isSharedPhoneNumber: o.isSharedPhoneNumber,
    sharedPhoneNumber: o.sharedPhoneNumber,
    sharedPhoneCode: o.sharedPhoneCode,
    isEnableActivationFlow: o.isEnableActivationFlow || false,
    isDisabled: o.isDisabled || false,
    corporateName: o.corporateName,
    languages: o.languages,
    defaultLanguage: o.defaultLanguage,
  };
};

function* loadOrgs() {
  try {
    const orgs = yield call(orgService.getAllAsync);
    const result = {};

    // Parse to ViewModel
    for (const org of orgs) {
      result[org.id] = _toViewModel(org);
    }

    yield put(getOrgsSuccess(result));
  } catch (error) {
    console.error(error);
    yield put(getOrgsError(error));
  }
}

function* loadOrg({ payload }) {
  const orgId = payload;

  try {
    const org = yield call(orgService.getByIdAsync, orgId);
    org.settings.relationships = yield call(relationshipService.getAllAsync, orgId);
    org.settings.locations = yield call(locationService.getAllAsync, orgId);
    org.settings.submissionTypes = yield call(typeService.getAllAsync, orgId);
    org.settings.submissionCustomFields = yield call(customFieldService.getAllAsync, orgId);
    yield put(getVoiceSettings(org.phoneNumber));
    yield put(getOrgSuccess(_toViewModel(org)));
  } catch (error) {
    console.error(error);
    yield put(getOrgError(error));
  }
}

function* loadUsers({ payload }) {
  const orgId = payload;

  try {
    const orgMembers = yield call(orgMemberService.getAllAsync, orgId);

    yield put(getUsersSuccess(orgMembers, orgId));
  } catch (error) {
    console.error(error);
    yield put(getUsersError(error));
  }
}

function* deleteOrgMember({ payload }) {
  try {
    const { uid, orgId } = payload;
    const member = { uid, orgId };

    const response = yield call(functions.httpsCallable("removeOrgMember"), member);

    if (response.data.success) {
      NotificationManager.secondary(
        intlMessages["info.delete/user"],
        intlMessages["general.done"],
        5000,
        () => {},
        null,
        "filled rounded-small",
      );

      yield put(deleteMemberSuccess(uid, orgId));
    } else {
      throw new Error(response.data.error);
    }
  } catch (error) {
    console.error("Deletion error:", error);

    NotificationManager.error(
      error.message || intlMessages["error.delete/user"],
      intlMessages["general.error"],
      5000,
      () => {},
      null,
      "filled rounded-small",
    );

    yield put(deleteMemberError(error.message || error));
  }
}

function* createOrgWebSettings(org) {
  // Check if webCode can be used
  const webSettings = yield call(webSettingsService.getByIdAsync, org.webCode);

  if (webSettings && webSettings.orgId !== org.id) {
    yield put(getOrgError("Invalid Webcode"));
    return;
  }

  // Create standard WebSettings data so the whistleblower's web channel is accessible from the start
  const defaultWebSettingsTitle = getDefaultFormValueByOrgType(org.type, "DEFAULT_TITLE").replace(
    "{{orgName}}",
    org.name,
  );
  const defaultWebSettingsLogoUrl = getDefaultFormValueByOrgType(org.type, "DEFAULT_LOGO_URL");
  const defaultWebSettingsColor = DEFAULT_COLOR;

  const standardWebSettings = new WebSettings(
    org.webCode,
    org.id,
    org.name,
    defaultWebSettingsTitle,
    defaultWebSettingsLogoUrl,
    defaultWebSettingsColor,
    new Date(),
    new Date(),
  );
  yield call(webSettingsService.addAsync, standardWebSettings);
}

function* addOrg({ payload }) {
  try {
    const { org, sendFormEmail } = payload;

    if (!org.isEnableActivationFlow) {
      yield* createOrgWebSettings(org);
    }

    if (sendFormEmail) {
      const emailEvent = new EmailNotificationEvent(
        createUUID(),
        {
          to: org.owner.email,
          templateName: "WELCOME_ORG",
          variables: {
            userName: org.owner.name,
            orgName: org.name || "",
            userEmail: org.owner.email,
          },
        },
        "SENDGRID",
        new Date(),
      );
      emailNotificationService.addAsync(emailEvent);
    }

    yield call(orgService.addAsync, org);

    yield put(getOrgSuccess(_toViewModel(org)));
    firebaseAnalyticsService.logEvent(ANALYTICS_ACTIONS.ADMIN_ORG_NEW);
  } catch (error) {
    console.error(error);
    yield put(getOrgsError(error));
  }
}

function* updateOrg({ payload }) {
  try {
    const { org, section, data } = payload;
    let orgToReturn = org;
    // verify each section and save properly
    // TODO Fix when remove viewModel
    switch (section) {
      case "general": {
        if (!data.isEnableActivationFlow) {
          // Check if or had an webCode
          if (org.webCode != "") {
            // get possibly pre-existing web settings with the new webCode
            const preExistingWebSettings = yield call(webSettingsService.getByIdAsync, data.webCode);

            // get the current org's web settings from the database (these are guaranteed to exist, since we're updating and not creating an org)
            const currentOrgWebSettings = yield call(webSettingsService.getByIdAsync, org.webCode);

            // check if web settings with this id (which is equal to the webCode) already exist
            // if they do, check if these settings belong to the same organization as the organization passed as an argument to this saga
            // if they don't, this webCode can't be used because it already belongs to another organization
            if (preExistingWebSettings && preExistingWebSettings.orgId !== org.id) {
              yield put(getOrgError("Invalid Webcode"));
              return;
            }

            // if they do belong to the same organization and the webCode has changed,
            // then we must copy the all the previous web settings data except for the webCode (which is being changed)
            if (data.webCode !== org.webCode) {
              const newWebSettings = new WebSettings(
                data.webCode,
                currentOrgWebSettings.orgId,
                org.name,
                currentOrgWebSettings.title,
                currentOrgWebSettings.logoUrl,
                currentOrgWebSettings.color,
                new Date(),
                new Date(),
              );

              yield call(webSettingsService.addAsync, newWebSettings);
            } // if the webCode hasn't changed, then we don't have to add or update any documents to the webSettings collection
          } else {
            yield* createOrgWebSettings({ ...data, id: org.id });
          }
        }

        yield call(orgService.updateGeneralAsync, org.id, data);
        break;
      }
      case "locations":
        yield call(locationService.updateAllAsync, org.id, data);
        break;

      case "relationships":
        yield call(relationshipService.updateAllAsync, org.id, data);
        break;

      case "submissionTypes":
        yield call(typeService.updateAllAsync, org.id, data);
        break;

      case "customFields":
        yield call(customFieldService.updateAllAsync, org.id, data);
        break;

      case "voiceSettings":
        yield call(voiceSettingsService.updateAsync, data);
        break;

      case "members":
        yield all(
          // U is OrgMember
          Object.keys(data).map(async (k) => {
            const u = data[k];
            const sendNotification = u.profile.sendActivateEmail;

            if (!u.profile.hasChanged) {
              return;
            }

            const userProfile = new UserProfile(
              u.profile.uid,
              u.profile.email,
              u.profile.name,
              u.profile.phoneNumber,
              u.profile.role,
              u.profile.level,
              u.profile.language,
            );

            const setOrgMemberFunction = functions.httpsCallable("setOrgMember");
            const params = {
              userProfile,
              orgRef: new OrgRef(org.id, org.name, false, org.type),
              sendNotification,
            };
            await setOrgMemberFunction(params);
          }),
        );

        yield put(getUsers(org.id));
        break;

      default:
        break;
    }

    orgToReturn = yield call(orgService.getByIdAsync, org.id);
    orgToReturn.settings.relationships = yield call(relationshipService.getAllAsync, org.id);
    orgToReturn.settings.locations = yield call(locationService.getAllAsync, org.id);
    orgToReturn.settings.submissionTypes = yield call(typeService.getAllAsync, org.id);
    orgToReturn.settings.submissionCustomFields = yield call(customFieldService.getAllAsync, org.id);
    yield put(getOrgSuccess(_toViewModel(orgToReturn)));
  } catch (error) {
    console.error(error);
    yield put(getOrgsError(error));
  }
}

function* setUser({ payload }) {
  try {
    const { org, member } = payload;

    const isCreatingUser = member?.uid === undefined;

    if (isCreatingUser) {
      const userAlreadyExists = user?.orgs.some((organization) => organization.id === org.id);
      if (userAlreadyExists) {
        NotificationManager.error(
          "Usuário já cadastrado na organização!",
          intlMessages["general.error"],
          5000,
          () => {},
          null,
          "filled rounded-small",
        );
        yield put(setMemberOrgError("error"));
        return;
      }
    }

    const setOrgMemberFunction = functions.httpsCallable("setOrgMember");

    const sendNotification = member.sendActivateEmail;
    if (!member.hasChanged) {
      return;
    }

    const userProfile = new UserProfile(
      member.uid,
      member.email,
      member.name,
      member.phoneNumber,
      member.role,
      member.level,
      member.language,
    );

    const params = {
      userProfile,
      submissionTypes: member.submissionTypes,
      orgRef: new OrgRef(org.id, org.name, false, org.type),
      isEnableActivationFlow: org.isEnableActivationFlow,
      sendNotification,
    };

    const user = yield call(setOrgMemberFunction, params);

    yield put(getUsers(org.id));

    NotificationManager.secondary(
      intlMessages["info.save/user"],
      intlMessages["general.done"],
      5000,
      () => {},
      null,
      "filled rounded-small",
    );

    yield put(setMemberOrgSuccess(org.id, user.data));
  } catch (error) {
    console.error(error);

    NotificationManager.error(
      intlMessages["error.save/user"],
      intlMessages["general.error"],
      5000,
      () => {},
      null,
      "filled rounded-small",
    );

    yield put(setMemberOrgError(error));
  }
}

function* disableOrg({ payload }) {
  const { orgId, disable } = payload;

  try {
    yield call(orgService.disableByIdAsync, orgId, disable);
    yield put(disableOrgSuccess(orgId, disable));
  } catch (error) {
    console.error(error);
    yield put(disableOrgError(error));
  }
}

export function* watchGetOrgs() {
  yield takeEvery(GET_ORGS, loadOrgs);
}

export function* watchGetOrg() {
  yield takeEvery(GET_ORG, loadOrg);
}

export function* watchGetUsers() {
  yield takeEvery(GET_ORG_USERS, loadUsers);
}

export function* watchAddOrg() {
  yield takeEvery(ADD_ORG, addOrg);
}

export function* watchUpdateOrg() {
  yield takeEvery(UPDATE_ORG, updateOrg);
}

export function* watchDeleteMember() {
  yield takeEvery(DELETE_ORG_MEMBER, deleteOrgMember);
}

export function* watchSetMembersOrg() {
  yield takeEvery(SET_MEMBER_ORGS, setUser);
}

export function* watchDisableOrg() {
  yield takeEvery(DISABLE_ORG, disableOrg);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetOrgs),
    fork(watchGetOrg),
    fork(watchGetUsers),
    fork(watchAddOrg),
    fork(watchUpdateOrg),
    fork(watchDeleteMember),
    fork(watchSetMembersOrg),
    fork(watchDisableOrg),
  ]);
}
