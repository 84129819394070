import { all } from "redux-saga/effects";

import aiSuggestionsSaga from "./aiSuggestions/saga";
import appSettingsSaga from "./appSettings/saga";
import authSaga from "./auth/saga";
import committeesSaga from "./committees/saga";
import filesSaga from "./files/saga";
import formPageSettingsSaga from "./formPageSettings/saga";
import formSettingsSaga from "./formSettings/saga";
import homePageSettingsSaga from "./homePageSettings/saga";
import marketingAssetsSaga from "./marketingAssets/saga";
import orgsSaga from "./org/saga";
import statusPageSettingsSaga from "./statusPageSettings/saga";
import usersSaga from "./user/saga";
import voiceSettingsSaga from "./voiceSettings/saga";
import whatsappSettingsSaga from "./whatsappSettings/saga";
import workflowEventTabSaga from "./workflow/eventTab/saga";
import workflowSaga from "./workflow/saga";
import workflow2Saga from "./workflow2/saga";

export default function* rootSaga() {
  yield all([
    authSaga(),
    orgsSaga(),
    usersSaga(),
    workflowSaga(),
    workflowEventTabSaga(),
    voiceSettingsSaga(),
    formSettingsSaga(),
    whatsappSettingsSaga(),
    workflow2Saga(),
    marketingAssetsSaga(),
    homePageSettingsSaga(),
    statusPageSettingsSaga(),
    formPageSettingsSaga(),
    appSettingsSaga(),
    filesSaga(),
    aiSuggestionsSaga(),
    committeesSaga(),
  ]);
}
